import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { Subscription, timer } from 'rxjs';

import { OmguiCloseXModule } from '@app/omgui/omgui-close-x/omgui-close-x.component';

import type { ToastMessage } from '../toast.service';

@Component({
  selector: 'om-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [trigger('fadeInOut', [state('void', style({ opacity: 0 })), transition('void <=> *', animate(200))])],
  standalone: true,
  imports: [CommonModule, OmguiCloseXModule],
})
export class ToastComponent implements OnDestroy {
  static timeoutMs = 6000;

  message: ToastMessage | null = null;
  private isOpen$: Subscription | null = null;

  ngOnDestroy(): void {
    this.stopTimer();
  }

  show(message: ToastMessage): void {
    if (message.action) {
      this.message = {
        content: message.content,
        action: {
          text: message.action.text,
          callback: () => {
            message.action?.callback();
            this.close();
          },
        },
      };
    } else {
      this.message = message;
    }
    this.closeIn(ToastComponent.timeoutMs);
  }

  close() {
    this.stopTimer();
    this.message = null;
  }

  private closeIn(timeMs) {
    this.stopTimer();
    this.isOpen$ = timer(timeMs).subscribe(() => this.close());
  }

  private stopTimer() {
    if (this.isOpen$) {
      this.isOpen$?.unsubscribe();
      this.isOpen$ = null;
    }
  }
}
