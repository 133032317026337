<div class="card hide-border-bottom" @enter [@cardContent]="pageState">
  <ng-container *ngIf="isSuccessState(pageState)">
    <ng-container *ngIf="rows.length; then rowView; else emptyView"></ng-container>

    <ng-template #rowView>
      <div class="card-header" *ngIf="header" @enter>
        <div class="row d-flex justify-content-between">
          <div
            *ngFor="let col of header.cols; let i = index"
            class="text-gray-sand"
            [ngClass]="{ 'col text-gray-sand small d-none d-sm-block': i !== 0 }"
            [class.col-6]="i === 0"
            [class.text-end]="i !== 0 && i === header.cols.length - 1"
          >
            <ng-template [ngTemplateOutlet]="col.templateRef"></ng-template>
          </div>
        </div>
      </div>
      <div class="list-group list-group-flush" *ngIf="rows.length" @enter>
        <ng-container *ngFor="let row of rows">
          <ng-template #footer>
            <div *ngIf="row.footer" class="footer small text-gray-sand">
              <ng-template [ngTemplateOutlet]="row.footer.templateRef"></ng-template>
            </div>
          </ng-template>

          <ng-container *ngIf="row.ready">
            <ng-container *ngIf="row.link; then linkRow; else textRow"></ng-container>

            <ng-template #linkRow>
              <a [routerLink]="row.link" class="list-group-item list-group-item-action">
                <ng-container *ngTemplateOutlet="rowContent"></ng-container>
              </a>
            </ng-template>

            <ng-template #textRow>
              <div class="list-group-item"> <ng-container *ngTemplateOutlet="rowContent"></ng-container> </div>
            </ng-template>

            <ng-template #rowContent>
              <ng-container *ngIf="row.multiColumns; then multiColumnView; else twoColumnView"></ng-container>
            </ng-template>

            <ng-template #multiColumnView>
              <div class="py-0 py-sm-3">
                <div class="d-flex justify-content-between row flex-column flex-sm-row">
                  <div
                    *ngFor="let col of row.cols; let i = index"
                    class="responsive-row d-flex d-sm-block mb-3 mb-sm-0"
                    [ngClass]="{
                      'col-xs col-sm-6 fw-bold text-start': i === 0,
                      'col text-gray-sand text-end': i !== 0
                    }"
                    [class.text-sm-start]="i !== row.cols.length - 1"
                    [class.text-sm-end]="i === row.cols.length - 1"
                  >
                    <div class="d-sm-none col text-start" *ngIf="i !== 0">
                      <ng-template
                        *ngIf="header.templateRefFor(i)"
                        [ngTemplateOutlet]="header.templateRefFor(i)"
                      ></ng-template>
                    </div>
                    <div class="col"> <ng-template [ngTemplateOutlet]="col.templateRef"></ng-template> </div>
                    <div *ngIf="row.link && i === 0" class="d-sm-none col inline-icon-with-text flex-row-reverse">
                      <om-svg-caret-right></om-svg-caret-right>
                    </div>
                  </div>
                  <div *ngIf="row.link" class="d-none d-sm-inline-flex px-3 inline-icon-with-text flex-row-reverse">
                    <om-svg-caret-right></om-svg-caret-right>
                  </div>
                </div>
                <ng-container *ngTemplateOutlet="footer"></ng-container>
              </div>
            </ng-template>

            <ng-template #twoColumnView>
              <div class="py-3">
                <div class="d-flex justify-content-between row">
                  <div
                    *ngFor="let col of row.cols; let i = index"
                    [ngClass]="{
                      'col-6 fw-bold text-start': i === 0,
                      'col text-gray-sand text-end': i !== 0
                    }"
                  >
                    <ng-template [ngTemplateOutlet]="col.templateRef"></ng-template>
                  </div>
                  <div *ngIf="row.link" class="px-3 inline-icon-with-text flex-row-reverse"
                    ><om-svg-caret-right></om-svg-caret-right
                  ></div>
                </div>

                <ng-container *ngTemplateOutlet="footer"></ng-container>
              </div>
            </ng-template>
          </ng-container>
        </ng-container>
      </div>
    </ng-template>

    <ng-template #emptyView>
      <div *ngIf="emptyState" class="card-body text-center" @enter>
        <ng-template [ngTemplateOutlet]="emptyState.templateRef"></ng-template>
      </div>
    </ng-template>
  </ng-container>
</div>
