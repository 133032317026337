export const FeatureFlags = {
  APPOINTMENT_SEARCH_NEXT_REMOTE_VISIT_MODULE: 'next-remote-visit-module',
  CONSUMER_MARKET_FLEXIBLE_PRICING_TOGGLE: 'consumer-market-flexible-pricing-toggle',
  CONSUMER_PEDIATRIC_REGISTRATION_FREE_TRIAL: 'consumer-pediatric-registration-free-trial',
  CONSUMER_PEDIATRIC_REGISTRATION_EMAIL_VERIFICATION_STEP: 'consumer-pediatric-registration-email-verification',
  CONSUMER_REGISTRATION_APP_SYNC_COMMUNICATION: 'consumer-registration-app-sync-communication',
  CONSUMER_REGISTRATION_APP_SYNC_DEACTIVATE_FLOW: 'consumer-registration-app-sync-deactivate-flow',
  CONSUMER_REGISTRATION_APP_SYNC_GIFTREDEEM_FLOW: 'consumer-registration-app-sync-giftredeem-flow',
  CONSUMER_REGISTRATION_APP_SYNC_PEDIATRIC_FLOW: 'consumer-registration-app-sync-pediatric-flow',
  CONSUMER_REGISTRATION_APPLE_PAY_WEB: 'consumer-registration-apple-pay-web',
  CONSUMER_REGISTRATION_DAY_1_BANNER: 'consumer-registration-day-1-banner',
  CONSUMER_REGISTRATION_EXPERIMENTATION_TEST: 'consumer-registration-experimentation-test',
  CONSUMER_REGISTRATION_GIFTING_POLLING_FLOW: 'consumer-registration-gifting-polling-flow',
  CONSUMER_REGISTRATION_INSTALLMENT_PAYMENTS: 'consumer-registration-installment-payments',
  CONSUMER_REGISTRATION_MARKET_BASED_PRICING: 'consumer-registration-market-based-pricing',
  CONSUMER_REGISTRATION_MEMBERSHIP_SERVICE_PUSHER_REFACTOR: 'consumer-registration-membership-service-pusher-refactor',
  CONSUMER_REGISTRATION_PASSWORDLESS_FIRST_PAGE: 'consumer-registration-passwordless-first-page',
  CONSUMER_REGISTRATION_PREFILL_WITH_SLLTOKEN: 'consumer-registration-prefill-with-slltoken',
  CONSUMER_REGISTRATION_PROMOTION: 'consumer-registration-promotion',
  CONSUMER_REGISTRATION_SERVICE_AREA_CAPACITY_ALERT_MODAL: 'consumer-registration-service-area-capacity-alert-modal',
  CONSUMER_REGISTRATION_SERVICE_AREA_ADULT_EXCLUSIONS:
    'consumer-registration-exclude-enterprise-only-service-areas-from-adult-flow',
  CONSUMER_REGISTRATION_UPSELL_VIRTUAL_OFFERING: 'consumer-registration-upsell-virtual-offering',
  CONSUMER_REGISTRATION_QUERY_PARAM: 'consumer-registration-query-param',
  CONSUMER_REGISTRATION_SERVICE_AREA_CAPACITY_BLOCK: 'consumer-registration-service-area-capacity-block',
  CONSUMER_REGISTRATION_STEP_BASED_BANNER: 'consumer-registration-step-based-banner',
  CONSUMER_REGISTRATION_STRIPE_PAYMENT_INTENTS: 'consumer-registration-stripe-payment-intents',
  CONSUMER_REGISTRATION_VALUE_PROPS_INTERSTITIAL: 'consumer-registration-value-props-interstitial-step-rev-2',
  ENTERPRISE_REGISTRATION_GTM_ANALYTICS: 'enterprise-registration-gtm-analytics',
  ENTERPRISE_PERSONAL_DETAILS: 'enterprise-personal-details',
  EXPEDITED_REGISTRATION_FLOW_WITH_SLLTOKEN: 'expedited-registration-flow-with-slltoken',
  FAMILY_ACCOUNT_MANAGEMENT_TAB: 'family-account-management-tab',
  GRAPHQL_OFFICES: 'graph-ql-migration-offices',
  GRAPHQL_MIGRATION_DELETE_PATIENT_TIMELINE_POST_COMMENT_DRAFT:
    'graph-ql-migration-delete-patient-timeline-post-comment-draft',
  HOME_SCREEN_LEGACY_RECOMMENDED_SERVICES: 'home-screen-legacy-recommended-services',
  MEMBERSHIP_NEW_STRIPE_WORKFLOW_GIFT_MEMBERSHIP: 'membership-new-stripe-workflow-gift-membership',
  PLAN_SELECTION_ROUTING_PAGE: 'plan-selection-routing-page',
  POST_REGISTRATION_BOOKING: 'post-registration-booking',
  BHX_MEMBER_PROGRAM_EXPERIENCE: 'member-program-experience',
  BHX_MENTAL_HEALTH_TOPIC_ROUTING_FEATURE: 'mental-health-topic-routing-feature',
  PATIENT_UI_MAINTENANCE_MODE: 'patient-ui-maintenance-mode',
  PROACTIVE_CARE_ENABLE_CONCIERGE: 'proactive-care-enable-concierge',
  PROACTIVE_CARE_ENABLE_PCP_SELECTION_ONBOARDING_CAROUSEL_CARD:
    'proactive-care-enable-pcp-selection-onboarding-carousel-card',
  REFERRAL_PDF: 'referral-pdf',
  PC_ONBOARDING_PROMPTS_EXPERIMENT: 'homescreen-onboarding-prompts-web',
  PC_GRAPHQL_NOTIFICATION_TARGETS: 'pc-messages-notification-targets-use-graphql',
  PC_FETCH_POSTS_USE_GRAPHQL: 'pc-fetch-posts-use-graphql',
  REGISTRATION_FAMILY_ROUTING_FAQS: 'registration-family-routing-faqs',
  ENTERPRISE_PERSONAL_DETAILS_VERIFICATION: 'enterprise-personal-details-verification',
  VEX_SWITCH_TO_REMOTE: 'switch-to-remote-visit',
  PATIENT_TASK_DETAILS_GRAPHQL: 'patient-task-details-graphql',
  TOPIC_BASED_GUIDANCE: 'topic-based-guidance',
  PC_REGISTRATION_SERVICE_AREA_SUGGESTION: 'pc-registration-service-area-suggestion',
  VEX_DISPLAY_AFTER_HOURS_BILLING_MESSAGE: 'display-after-hours-billing-message',
  MFA_ADD_EMAIL: 'mfa-settings-add-email',
  REGISTRATION_MFA_CONSUMER: 'mfa-step-consumer-registration',
  REGISTRATION_MFA_ENTERPRISE: 'mfa-step-enterprise-registration',
  REGISTRATION_MFA_PEDIATRIC: 'mfa-step-pediatric-registration',
  REGISTRATION_MFA_DSU: 'mfa-step-dsu',
  REGISTRATION_MFA_PEDIATRIC_DSU: 'mfa-step-pediatric-dsu',
  REGISTRATION_MFA_BACKUP_EMAIL: 'mfa-step-backup-email-factor',
  MFA_EDIT_PHONE: 'mfa-edit-phone-settings',
  MFA_SETTINGS_ENROLL: 'mfa-settings-enroll',
  ACCOUNT_SETTINGS_CONNECTED_DEVICES: 'account-settings-connected-devices',
  PATIENT_DOWNLOAD_EHI_EXPORT: 'patient-download-ehi-export',
  EHI_EXPORT_HISTORY: 'ehi-export-history',
  PC_FETCH_PAGINATED_PATIENT_TASKS: 'pc-fetch-paginated-patient-tasks',
  PC_TMN_QR_CODE_MOBILE_FORWARDING: 'pc-tmn-qr-code-mobile-forwarding',
  PEDS_REGISTRATION_HIDE_ACCOUNT_DETAILS: 'peds-registration-hide-account-details',

  // Hornbill
  HORNBILL_ENABLE_LANDING_PAGE_ANALYTICS: 'hornbill-enable-landing-page-analytics',
  HORNBILL_ENABLE_CONVERSION_PAGE_ANALYTICS: 'hornbill-enable-conversion-page-analytics',
  HORNBILL_ENABLE_FAMILY_TYPE_PAGE_ANALYTICS: 'hornbill-enable-family-type-page-analytics',
  HORNBILL_ENROLLMENT_P0_ENABLED: 'hornbill-enrollment-p0-enabled', // Hornbill Enrollment related P0 features
  HORNBILL_REGISTRATION_BANNER: 'hornbill-registration-reminder-banner',
  HORNBILL_MEMBERSHIP_MANAGEMENT_P0_ENABLED: 'hornbill-membership-mgmt-p0-enabled',
  HORNBILL_DISABLE_REFERRAL_CODE: 'hornbill-disable-referral-code',
  HORNBILL_ERROR_PAGE_ANALYTICS_ENABLED: 'hornbill-error-page-analytics-hotfix',
} as const;
export type FeatureFlags = typeof FeatureFlags[keyof typeof FeatureFlags];

export enum HomeRedesignVariation {
  OFF = 'Off',
  ON_ALTERNATE_TEXT = 'OnAlternateText',
}

/**
 * Variants for LaunchDarkly (LD) x MixPanel (MP) workaround.
 */
export const FeatureFlagVariants = {
  /** Indicates the experiment is not running and should tell the client to not send LD parameters to MP*/
  OFF: 'OFF',
  /** Indicates the experiment is running and should tell the client to send LD parameters to MP for members assigned to the control group*/
  ON_CONTROL: 'ON CONTROL',
  /** Indicates the experiment is running and should tell the client to send LD parameters to MP for members assigned to the variant group*/
  ON_VARIANT: 'ON VARIANT',
} as const;
export type FeatureFlagVariants = typeof FeatureFlagVariants[keyof typeof FeatureFlagVariants];

/**
 * Variants for LaunchDarkly (LD) x MixPanel (MP) workaround with `ON` variation
 */
export const FeatureFlagVariantsWithOn = {
  ...FeatureFlagVariants,
  /** Indicates the experiment is running and should tell the client to not send LD parameters to MP.*/
  ON: 'ON',
} as const;
export type FeatureFlagVariantsWithOn = typeof FeatureFlagVariantsWithOn[keyof typeof FeatureFlagVariantsWithOn];
